import React from 'react';

import { Container, Row, Col } from 'reactstrap';

import { formatDate } from '../utils/dates';
import Link from '../net/Link';
/**
 * ProjectImageBlock is similar to Leftblock, but we show the headline on top of both image and text.
 * 
 * @class ProjectImageBlock
 * @extends {React.Component}
 */
class ProjectImageBlock extends React.Component {
  render() {
    let date = formatDate(this.props.date);

    return (
      <Container fluid={true} className="leftblock sideblock block w-80">
      <h1>{this.props.headline}<span className="date">{date}</span></h1>
        <Row className="align-items-top">
          <Col sm="12" md={{size: 5, offset: 0}} className="left">
            <Link to={this.props.link}>
              <img src={this.props.image_src} className="img-fluid" alt={this.props.image_alt}/>
            </Link>
          </Col>
          <Col sm="12" md={{size:5, offset: 0}} className="right">
          {this.props.children}
            <Link to={this.props.link}>
              <p className="lead" dangerouslySetInnerHTML={{__html:this.props.lead}}/>
              <p><span className="readmore">Read more</span></p>
            </Link>
          </Col>
        </Row>
      </Container>
    )
  }
}

ProjectImageBlock.defaultProps = {
  headline: '',
  date: '2017-11-20T07:00:00+00:00',
  lead: '',
  image_src: '',
  image_alt: '',
  link: '/'
}

export default ProjectImageBlock;