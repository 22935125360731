import React from 'react';

import { Container } from 'reactstrap';

import Link from '../net/Link';

import ProjectImageBlock from '../components/ProjectImageBlock';
import { formatDate } from '../utils/dates';

class Projectblock extends React.Component {
  render() {

    let contentblock = null;
    let date = formatDate(this.props.date);
    let headline = this.props.headline + '<span class=date>' + date + '</span>';
    if (this.props.link) {
      contentblock =  (
          <Container fluid={true} className="leftblock sideblock block w-80">
            <Link to={this.props.link}>
            <h1 dangerouslySetInnerHTML={{__html: headline}}/>
              <p className="lead" dangerouslySetInnerHTML={{__html:this.props.lead}}/>
              <p><span className="readmore">Read more</span></p>
            </Link>
          </Container>
      );
    } else {
      contentblock = (
          <Container fluid={true} className="leftblock sideblock block w-80">
            <h1>{this.props.headline}<span className="date">{date}</span></h1>
            <p className="lead" dangerouslySetInnerHTML={{__html:this.props.lead}}/>
          </Container>
      )
    }

    if (this.props.image_src !== '') {
      contentblock = (
        <ProjectImageBlock 
          headline={this.props.headline}
          date={this.props.date}
          lead={this.props.lead}
          image_src={this.props.image_src}
          image_alt={this.props.image_alt}
          link={this.props.link} />
        
      )
    }

    return (
           contentblock
    )
  }
}

Projectblock.defaultProps = {
  headline: '',
  date: '2017-11-20T07:00:00+00:00',
  lead: '',
  image_src: '',
  image_alt: '',
  link: undefined
}

export default Projectblock;