import React from 'react';

import Footer from '../../components/Footer';

import { Container, Row, Col } from 'reactstrap';


import Projectblock from '../../components/Projectblock';
import Layout from '../../components/layout';
import SEO from '../../components/seo';

import moment from 'moment';
import { graphql } from 'gatsby';
import ScrollingEff from '../../components/ScrollingEff';

class Articles extends React.Component {

  render() {


    let title = "Articles";
    let content = "Here's chronological archive of my texts. <a href='/articles'>Go here if you prefer the contextual listing.</a>";

    let edges = this.props.data.allMarkdownRemark.edges;

    let childNodes = edges.map(edge => {

      let headline = edge.node.frontmatter.title;
      let date = edge.node.frontmatter.date;
      let link = edge.node.frontmatter.path;
      let lead = edge.node.frontmatter.abstract;
      let image = edge.node.frontmatter.featureImage || "";
      let now = moment();
      //only return nodes with a publication date not set to the future
      //and don't show the overview page
      if (now.isSameOrAfter(moment(date)) && link !== '/articles/') {      
        return (
          <Projectblock
            headline={headline}
            date={date}
            link={link}
            lead={lead}
            image_src={image}
            image_alt={title}
            key={headline}
          />
        );
      } else {
        return null;
      }

    });



    return (
      <Layout>
        <SEO title="Articles" />
        <ScrollingEff />
        <div>
          <Container fluid className="w-80 content-page block">

            <Row>
              <Col>
                <h1 dangerouslySetInnerHTML={{ __html: title }} />
                <div dangerouslySetInnerHTML={{ __html: content }} />
              </Col>
            </Row>
          </Container>
          {childNodes}

          <Footer />
        </div>
      </Layout>

    )
  }
}

export default Articles;


export const pageQuery = graphql`
    query {
allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]}, filter: {frontmatter: {path: {regex: "/articles/"}, date: {}, lang: {}}}) {
  edges {
    node {
      id
      excerpt(pruneLength: 250)
      html
      frontmatter {
        date
        path
        featureImage
        title
        abstract
      }
    }
  }
}}`