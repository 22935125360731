import React from 'react';
import { Container, Row, Col } from 'reactstrap';

import Link from '../net/Link';

class Footer extends React.Component {

  render() {
    return (
      <Container fluid className="footer">
        <Row>
          <Col className="text-center">
          <div className="wrapper">
            <span className="align-middle"><Link to="/lets-be-fwends/">Let's be Fwends</Link></span>
          </div>
          </Col>
        </Row>
      </Container>
    )
  }

}

export default Footer;